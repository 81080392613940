<template>
    <v-text-field v-bind="$attrs" v-model.number="defaultedValue"></v-text-field>
</template>

<script>
export default {
    name: 'default-text-field',
    inheritAttrs: false,
    props: {
        value: [String, Number],
        default: [String, Number],
    },
    computed: {
        defaultedValue: {
            get: function get() {
                // If our v-model value is undefined, return null to use today's date
                if (this.value === undefined) {
                    return this.default;
                }
                return this.value;
            },
            set: function set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>
