<template><!-- eslint-disable -->
  <div id="login-box">
    <div id="login-box-title">Login</div>
    <div id="access-code-wrapper">
      <v-text-field id="access-code" v-model="accessCode" label="Access Code" outline
          v-bind:error-messages="error" v-on:keyup.enter="logIn"
          style="padding-bottom: 20px; text-align: center"></v-text-field>
    </div>
    <v-btn v-on:click="logIn" block class="indigo white--text">SIGN IN</v-btn>
  </div>
</template>

<script>
/* eslint-disable */
import { API_BASE, WINDOW_TITLE } from '../site-consts';
import { appData } from '../meter-data';

export default {
    data: function data() {
        return {
            // accessCode contains the value currently typed into the Access Code field
            accessCode: '',
            // The error attribute is binded to the error-messages property of the access code
            // v-text-field. This attr is updated in the logIn method when auth fails.
            error: '',
        };
    },
    methods: {
        logIn: async function logIn() {
            const authResp = await fetch(`${API_BASE}/authenticate`, {
                method: 'POST',
                mode: 'cors',
                credentials: 'include',
                body: `access_code=${this.accessCode}`,
            });
            if (authResp.ok) {
                appData.authenticated = true;
                appData.access = (await authResp.json()).access;
                if (this.$route.query.nextUrl) {
                    this.$router.push(this.$route.query.nextUrl);
                } else {
                    this.$router.push('/');
                }
            } else {
                // Authentication was unsuccessful. Display the error.
                this.error = await authResp.text();
            }
        },
    },
    created: function created() {
        document.title = `${WINDOW_TITLE} Login`;
    },
};
</script>
