// Functions related to the meter data
// TODO use Vue.set to set new keys in dicts so that Vue reacts correctly
import Vue from 'vue';
import Papa from 'papaparse';
import Dexie from 'dexie';
import UploadQueue from './UploadQueue';
import { API_BASE, STORAGE_KEY } from './site-consts';
import {
    INITIAL_SORT_PROPS, PROP_TYPES, METER_EDIT_PROPS, ROUTE_KEY,
    METER_PROPS, PHOTOS,
} from './meter-props';
import appLogger from './logger';

export const appData = {
    meters: [],
    metersById: {},
    metersByRoute: {},
    modifiedMeters: {},
    uploadQueue: new UploadQueue(),
    lastUpdate: undefined,
    authenticated: false,
    lastUsedSettings: {},
};
const logger = appLogger.getLogger('meter-data');
// logger.logLevel = logger.loggerLevels.debug;

// DB Configuration
const INDEXED_DB_NAME = 'MeterSwap';
export const db = new Dexie(INDEXED_DB_NAME);
db.version(1).stores({
    Meters: '&ID, Route',
});

function compareMeters(a, b) {
    const flogger = logger.getLogger('compareMeters');
    // flogger.logLevel = flogger.loggerLevels.debug;
    // Use the following commented code to log details for only a specific meter
    // flogger.logLevel = undefined;
    // if (a.ID === '3dc3f4ee-440a-4e22-b467-5f2caeb0381f'
    //     || b.ID === '3dc3f4ee-440a-4e22-b467-5f2caeb0381f') {
    //     flogger.logLevel = flogger.loggerLevels.debugall;
    // }
    flogger.debug(`Comparing ${JSON.stringify(a)} vs ${JSON.stringify(b)}`);
    for (let i = 0; i < INITIAL_SORT_PROPS.length; i++) {
        try {
            flogger.debug(`Comparing key ${INITIAL_SORT_PROPS[i].key}`);
            // TODO Log an error if keys aren't there
            if (a[INITIAL_SORT_PROPS[i].key] === b[INITIAL_SORT_PROPS[i].key]) {
                flogger.debugall('Values are equal. Continuing to next sort key, if there is one.');
                if (i === INITIAL_SORT_PROPS.length - 1) {
                    flogger.debugall('No more sort keys. Returning 0 to indicate the meters are equal.');
                    return 0;
                }
                continue;
            }
            if (INITIAL_SORT_PROPS[i].type === PROP_TYPES.NUMBER) {
                // If comparing numbers, do a subtraction
                return a[INITIAL_SORT_PROPS[i].key] - b[INITIAL_SORT_PROPS[i].key];
            }
            // If we made it past the above IFs, assume its a string
            // If comparing strings, use String.localeCompare()
            if (a[INITIAL_SORT_PROPS[i].key] === undefined) {
                return -1;
            }
            return a[INITIAL_SORT_PROPS[i].key].localeCompare(b[INITIAL_SORT_PROPS[i].key]);
        } catch (e) {
            flogger.warning(`Error comparing ${JSON.stringify(a)} vs ${JSON.stringify(b)} with sort prop ${JSON.stringify(INITIAL_SORT_PROPS[i])}: ${e.message}. Continuing to the next sort prop.`);
        }
    }
    return 0;
}

export class UnauthorizedError extends Error {
    constructor(message) {
        super(message);
        this.name = 'UnauthorizedError';
    }
}

export function updateCompletedFlag(meter) {
    const flogger = logger.getLogger('updateCompletedFlag');
    // Use the following commented code to log details for only a specific meter
    // flogger.logLevel = undefined;
    // if (meter.ID === '864141a4-2e55-440e-b178-c94b39573373') {
    //     flogger.logLevel = flogger.loggerLevels.debug;
    // }
    flogger.debug(`Updating completed flag for meter ${meter.ID}`);
    // To determine if we're completed, we'll go through all of the meter props and check that all
    // modifiable props that don't have optional == true have values.
    for (let i = 0; i < METER_PROPS.length; i++) {
        const prop = METER_PROPS[i];
        flogger.debugall(`Checking meter prop ${prop.key}`);
        if (prop.condition && !prop.condition(meter)) {
            flogger.debugall('Prop has a condition function and the function returned false. Skipping.');
            continue;
        }
        if (prop.completed) {
            // Our prop has a custom completed function that returns true if this prop is completed
            if (!prop.completed(meter, prop)) {
                flogger.debug(`Field ${prop.key} is not set. Marking not completed.`);
                meter.Completed = false;
                return false;
            }
        } else if (prop.modifiable && !prop.optional && prop.defaultValue === undefined
                && meter[prop.key] === undefined) {
            flogger.debug(`Field ${prop.key} is not set. Marking not completed.`);
            meter.Completed = false;
            return false;
        }
    }
    // Check that the photos have been captured
    for (let i = 0; i < PHOTOS.length; i++) {
        const photo = PHOTOS[i];
        flogger.debugall(`Checking photo prop ${photo.key}`);
        if (photo.optional) {
            flogger.debugall('This photo is optional. Skipping.');
            continue;
        }
        if (photo.condition && !photo.condition(meter)) {
            flogger.debugall('Photo has a condition function and the function returned false. Skipping.');
            continue;
        }
        if (meter[photo.key] === undefined) {
            flogger.debug(`Photo ${photo.key} is not set. Marking not completed.`);
            meter.Completed = false;
            return false;
        }
    }
    flogger.debug('Meter is completed. Setting Completed flag to true.');
    meter.Completed = true;
    return true;
}

export async function loadAppDataFromStorage() {
    const flogger = logger.getLogger('loadAppDataFromStorage');
    // We prefer to store our data in localStorage. But, if that's not supported in our browser,
    // we'll store it in sessionStorage.
    const storedData = sessionStorage.getItem(STORAGE_KEY) || localStorage.getItem(STORAGE_KEY);
    if (!storedData) {
        flogger.info('No data found in local or session storage.');
        return;
    }
    let logInfo = 'Stored data found.';
    flogger.debugall(`Stored data string: ${JSON.stringify(storedData)}`);
    // At this point, we found data stored locally, lets unpack it correctly.
    const storedDataObj = JSON.parse(storedData);
    flogger.debugall(`Stored data parsed: ${JSON.stringify(storedDataObj)}`);
    if (storedDataObj.meterKeyMap !== undefined && storedDataObj.meters !== undefined) {
        // If we've compressed our data, decompress it
        for (let i = 0; i < storedDataObj.meters.length; i++) {
            const meter = storedDataObj.meters[i];
            const meterKeys = Object.keys(meter);
            for (let j = 0; j < meterKeys.length; j++) {
                const key = meterKeys[j];
                if (storedDataObj.meterKeyMap[key] !== undefined) {
                    const newKey = storedDataObj.meterKeyMap[key];
                    meter[newKey] = meter[key];
                    delete meter[key];
                }
            }
        }
    }
    if (storedDataObj.meters === undefined) {
        // No meters in local or session storage. Lets check indexedDB.
        storedDataObj.meters = await db.Meters.toArray();
    }
    if (storedDataObj.meters) {
        appData.meters = storedDataObj.meters.sort(compareMeters);
    } else {
        flogger.warning('No meters found in stored data. Were no meters loaded last time?');
        // We must have some weird data stored. Its ok, we'll deal with it.
        appData.meters = [];
    }
    logInfo += ` ${appData.meters.length} meters.`;
    // Recreate our metersById and metersByRoute maps. We need to recreate these pointers or they
    // will point to different objects than our meters array.
    for (let i = 0; i < appData.meters.length; i++) {
        const meter = appData.meters[i];
        updateCompletedFlag(meter);
        appData.metersById[meter.ID] = meter;
        if (meter[ROUTE_KEY] === undefined) {
            // For some reason, we don't have a route name for this meter. Lets put it in a route
            // called "Other" and then log a single warning later.
            meter[ROUTE_KEY] = 'Other';
        }
        if (!appData.metersByRoute[meter[ROUTE_KEY]]) {
            appData.metersByRoute[meter[ROUTE_KEY]] = [];
        }
        appData.metersByRoute[meter[ROUTE_KEY]].push(meter);
    }
    if (Array.isArray(appData.metersByRoute.Other)) {
        flogger.warning(`${appData.metersByRoute.Other.length} meters do not have a route `
            + `key "${ROUTE_KEY}" and have been placed in the "Other" route. 1st meter ID: `
            + `${appData.metersByRoute.Other[0].ID}.`);
    }
    flogger.debugall(`appData.metersById updated to be ${JSON.stringify(appData.metersById)}`);
    if (storedDataObj.lastUpdate) {
        flogger.debug(`Last update string in storage: ${JSON.stringify(storedDataObj.lastUpdate)}`);
        // console.log(`Setting last update to ${storedDataObj.lastUpdate}`);
        appData.lastUpdate = new Date(storedDataObj.lastUpdate);
        flogger.debug(`Parsed last update: ${JSON.stringify(appData.lastUpdate)}`);
        logInfo += ` Last updated ${appData.lastUpdate}.`;
    }
    if (storedDataObj.uploadQueue) {
        flogger.debug('Stored data contains an upload queue. Parsing.');
        // Recreate our uploadQueue with the correct objects
        appData.uploadQueue = UploadQueue.fromJSONObj(storedDataObj.uploadQueue);
        logInfo += ` Upload queue with ${appData.uploadQueue.inProgress.length} transfers in progress, ${appData.uploadQueue.queue.length} transfers queued, and ${appData.uploadQueue.completed.length} transfers completed.`;
        flogger.debug('Done parsing upload queue.');
    }
    if (storedDataObj.modifiedMeters) {
        appData.modifiedMeters = storedDataObj.modifiedMeters;
        const modifedMeterIds = Object.keys(appData.modifiedMeters).map((id) => id.substr(-5));
        logInfo += ` ${modifedMeterIds.length} modified meters`;
        if (modifedMeterIds.length > 0) {
            logInfo += `: ${modifedMeterIds.join(', ')}`;
        }
        logInfo += '.';
    }
    if (storedDataObj.authenticated) {
        appData.authenticated = storedDataObj.authenticated;
        logInfo += ' Previously authenticated.';
    }
    if (storedDataObj.lastUsedSettings) {
        appData.lastUsedSettings = storedDataObj.lastUsedSettings;
    }
    if (storedDataObj.access) {
        appData.access = storedDataObj.access;
        logInfo += ` Access: ${storedDataObj.access}.`;
    }
    flogger.info(logInfo);
}

const timerData = {};

// We had to rename this function with the 2 on the end b/c for some reason the export wasn't
// working to the app-bar. Weird ...
export async function periodicSaveOfAppDataToStorage2() {
    timerData.saveTimerId = undefined;
    const flogger = logger.getLogger('saveAppDataToStorage');
    flogger.logLevel = flogger.loggerLevels.debug;
    flogger.debug('Saving app data to storage.');
    // To save space, lets strip out the duplicate data
    const appDataCopy = JSON.parse(JSON.stringify(appData));
    delete appDataCopy.metersById;
    delete appDataCopy.metersByRoute;
    // Store the meters into IndexedDB
    await db.Meters.bulkPut(appDataCopy.meters);
    delete appDataCopy.meters;
    const appDataStr = JSON.stringify(appDataCopy);
    flogger.debug('Length of data being saved to local storage: '
        + `${appDataStr.length}`);
    // // Lets shorten our data by using a key lookup. The key map will be needed to parse.
    // appDataCopy.meterKeyMap = {};
    // const keysMapped = {};
    // let nextCharCodeOffset = 0;
    // const newKeys = [  // TODO Make this longer with aa, ab, ac, etc.
    //     'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
    //     'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D',
    //     'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'W', 'X', 'Y', 'Z',
    //     'aa', 'ab', 'ac', 'ad', 'ae', 'af', 'ag', 'ah', 'ai', 'aj', 'ak', 'al', 'am', 'an', 'ao',
    //     'ap', 'aq', 'ar', 'as', 'at', 'au', 'av', 'aw', 'ax', 'ay', 'az', 'aA', 'aB', 'aC', 'aD',
    //     'aE', 'aF', 'aG', 'aH', 'aI', 'aJ', 'aK', 'aL', 'aM', 'aN', 'aW', 'aX', 'aY', 'aZ',
    // ];
    // for (let i = 0; i < appDataCopy.meters.length; i++) {
    //     const meter = appDataCopy.meters[i];
    //     const meterKeys = Object.keys(meter);
    //     for (let j = 0; j < meterKeys.length; j++) {
    //         const key = meterKeys[j];
    //         if (keysMapped[key] === undefined) {
    //             const newKey = newKeys[nextCharCodeOffset++];
    //             if (newKey === undefined) {
    //                 flogger.error('Not enough key maps present. Unable to map key.');
    //                 continue;
    //             }
    //             keysMapped[key] = newKey;
    //             appDataCopy.meterKeyMap[keysMapped[key]] = key;
    //         }
    //     }
    // }
    // // Now that we have a key map, lets convert
    // for (let i = 0; i < appDataCopy.meters.length; i++) {
    //     const meter = appDataCopy.meters[i];
    //     const meterKeys = Object.keys(meter);
    //     for (let j = 0; j < meterKeys.length; j++) {
    //         const key = meterKeys[j];
    //         const newKey = keysMapped[key];
    //         if (newKey === undefined) {
    //             flogger.warning(`Key ${key} is missing in the key map`);
    //             continue;
    //         }
    //         meter[newKey] = meter[key];
    //         delete meter[key];
    //     }
    // }
    // appDataStr = JSON.stringify(appDataCopy);
    // flogger.debug('Length of data being saved to local storage after compression: '
    //     + `${appDataStr.length}`);
    try {
        localStorage.setItem(STORAGE_KEY, appDataStr);
    } catch (err) {
        flogger.warning('WARNING Unable to store meter data in localstorage. Error: '
            + `${err.message}. Length: ${appDataStr.length}.`);
        try {
            sessionStorage.setItem(STORAGE_KEY, appDataStr);
        } catch (sessStorageError) {
            flogger.error('WARNING Unable to store meter data in sessionstorage. Error: '
                + `${sessStorageError.message}`);
            // TODO Decide if we should throw an error here
        }
    }
    flogger.debug('App data save to local storage complete.');
}

export function saveAppDataToStorage() {
    if (timerData.saveTimerId === undefined) {
        timerData.saveTimerId = setTimeout(periodicSaveOfAppDataToStorage2, 5000);
    }
}

function cleanModifiedMeters() {
    // This function will go through the list of modified meters and remove any that match what
    // we have in meters.
    const flogger = logger.getLogger('cleanModifiedMeters');
    flogger.debug('Cleaning up our list of modified meters ...');
    const modifiedMeterIds = Object.keys(appData.modifiedMeters);
    for (let i = 0; i < modifiedMeterIds.length; i++) {
        const meterId = modifiedMeterIds[i];
        if (appData.metersById[meterId] === undefined) {
            flogger.error(`Meter ${meterId} is in the list of modified meters but not in metersById. How did it end up in our modified meters list? Deleting from modified meters.`);
            Vue.delete(appData.modifiedMeters, meterId);
            continue;
        }
        const meterCopy = JSON.parse(JSON.stringify(appData.metersById[meterId]));
        const modMeterCopy = JSON.parse(JSON.stringify(appData.modifiedMeters[meterId]));
        delete meterCopy.Version;
        delete meterCopy.VersionDate;
        delete meterCopy.VersionTime;
        delete meterCopy.Completed;
        delete meterCopy['Image final-read URL'];
        delete meterCopy['Image new-meter URL'];
        delete meterCopy['Image post-exchange URL'];
        delete meterCopy['Image town-attention URL'];
        delete meterCopy['Image uninstallable URL'];
        delete modMeterCopy.Version;
        delete modMeterCopy.VersionDate;
        delete modMeterCopy.VersionTime;
        delete modMeterCopy.Completed;
        delete modMeterCopy['Image final-read URL'];
        delete modMeterCopy['Image new-meter URL'];
        delete modMeterCopy['Image post-exchange URL'];
        delete modMeterCopy['Image town-attention URL'];
        delete modMeterCopy['Image uninstallable URL'];
        const meterCopyJsonStr = JSON.stringify(meterCopy, Object.keys(meterCopy).sort());
        const modMeterCopyJsonStr = JSON.stringify(modMeterCopy, Object.keys(modMeterCopy).sort());
        if (meterCopyJsonStr === modMeterCopyJsonStr) {
            flogger.debug(`Removing meter ${meterId} from the list of modified meters`);
            Vue.delete(appData.modifiedMeters, meterId);
        } else {
            flogger.debugall(`Leaving meter ${meterId} in the list of modified meters`);
            flogger.debugall(`Meter: ${meterCopyJsonStr}`);
            flogger.debugall(`Modified Meter: ${modMeterCopyJsonStr}`);
            for (let j = 0; j < meterCopyJsonStr.length; j++) {
                if (meterCopyJsonStr[j] !== modMeterCopyJsonStr[j]) {
                    flogger.debugall(`Diff at char ${j}: ${meterCopyJsonStr.substring(j)}`);
                    break;
                }
            }
        }
    }
}

export async function getMeterData() {
    const flogger = logger.getLogger('getMeterData');
    let respData = {};
    appData.meters = [];
    do {
        let url = `${API_BASE}/meters`;
        if (respData.next) {
            url += `?next=${respData.next}`;
        }
        // We want to await in the loop b/c the next loop depends on the await data
        // eslint-disable-next-line
        const getRecordsResp = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            credentials: 'include',
        });
        if (getRecordsResp.ok === false) {
            if (getRecordsResp.status === 401) {
                // We aren't authenticated
                flogger.warning('We need authentication to get our data. Throwing an error. '
                    + 'The handler should probably go to the login page ...');
                throw new UnauthorizedError('The user is not authenticated.');
            }
            flogger.error(`Non-OK ${getRecordsResp.status} response received from GET ${url}. `
                // eslint-disable-next-line
                + `Response Body: ${await getRecordsResp.text()}`);
            break;
        }
        // We want to await in the loop b/c the next loop depends on the await data
        // eslint-disable-next-line
        respData = await getRecordsResp.json();
        appData.meters = appData.meters.concat(respData.data.sort(compareMeters));
        appData.lastUpdate = new Date(respData.queryTime);
        for (let i = 0; i < appData.meters.length; i++) {
            const meter = appData.meters[i];
            Vue.set(appData.metersById, meter.ID, meter);
            if (meter[ROUTE_KEY]) {
                if (!appData.metersByRoute[meter[ROUTE_KEY]]) {
                    Vue.set(appData.metersByRoute, meter[ROUTE_KEY], []);
                }
                appData.metersByRoute[meter[ROUTE_KEY]].push(meter);
            }
            updateCompletedFlag(meter);
        }
        // TODO Fix a bug ... If we are currently on the meter edit page when this code is
        // executed, our modified meter will get deleted out from under us. Then, photos taken
        // won't show up on the page. You can reproduce this issue by doing
        // Vue.delete(modifiedMeters[...]) while on the edit page. Then, take a photo.
    } while (respData.next !== undefined);
    cleanModifiedMeters();
}

export async function updateMeterData() {
    const flogger = logger.getLogger('updateMeterData');
    // flogger.logLevel = flogger.loggerLevels.debug;
    flogger.debug('Updating meter data ...');
    if (!appData.lastUpdate || appData.lastUpdate < (Date.now() - (1000 * 60 * 60 * 24 * 30))) {
        // If we've never updated or our data is more than 30 days old, lets just replace all of
        // our data.
        flogger.debug('Never updated or updated a long time ago. Getting all data.');
        await getMeterData();
    }
    let metersAdded = false;
    // Lets keep track of the IDs for logging after we're done iterating
    const newRecordIds = [];
    const updatedRecordIds = [];
    let respData = {};
    do {
        // TODO Optimize to only get updated today after its been updated
        const url = `${API_BASE}/meters?updated-after=${encodeURIComponent(appData.lastUpdate.toISOString())}`;
        flogger.debug(`Fetching data updated after ${appData.lastUpdate.toISOString()}`);
        // eslint-disable-next-line
        const getRecordsResp = await fetch(
            url, {
                method: 'GET',
                mode: 'cors',
                credentials: 'include',
            },
        );
        if (!getRecordsResp.ok) {
            // if (getRecordsResp.status === 401) {
            //     // Redirect to the /login endpoint.
            // }
            // eslint-disable-next-line
            throw new Error(`Received a ${getRecordsResp.status} response from ${url} with body ${await getRecordsResp.text()}`);
        }
        // TODO Handle 401 response
        flogger.debug('Response received OK. Parsing JSON ...');
        // eslint-disable-next-line
        respData = await getRecordsResp.json();
        const updatedRecords = respData.data;
        appData.lastUpdate = new Date(respData.queryTime);
        flogger.debug(`Updating ${updatedRecords.length} meters ...`);
        for (let i = 0; i < updatedRecords.length; i++) {
            const record = updatedRecords[i];
            if (!appData.metersById[record.ID]) {
                // A new meter
                newRecordIds.push(record.ID);
                Vue.set(appData.metersById, record.ID, record);
                // appData.metersById[record.ID] = record;
                if (record[ROUTE_KEY]) {
                    if (!appData.metersByRoute[record[ROUTE_KEY]]) {
                        Vue.set(appData.metersByRoute, record[ROUTE_KEY], []);
                        // appData.metersByRoute[record[ROUTE_KEY]] = [];
                    }
                    appData.metersByRoute[record[ROUTE_KEY]].push(record);
                }
                appData.meters.push(record);
                metersAdded = true;
            } else {
                // The meter was updated. Lets just update the attributes b/c we don't want to break
                // pointers.
                updatedRecordIds.push(record.ID);
                flogger.debugall(`Meter ${record.ID} updated. New meter details: ${JSON.stringify(record)}`);
                const oldData = appData.metersById[record.ID];
                const recordKeys = Object.keys(record);
                for (let j = 0; j < recordKeys.length; j++) {
                    // TODO Check if this triggers a lot of local storage saves & optimize
                    // if it does
                    Vue.set(oldData, recordKeys[j], record[recordKeys[j]]);
                }
                // Delete any keys that were deleted
                const oldDataKeys = Object.keys(oldData);
                for (let j = 0; j < oldDataKeys.length; j++) {
                    const key = oldDataKeys[j];
                    if (record[key] === undefined) {
                        Vue.delete(oldData, key);
                    }
                }
                updateCompletedFlag(oldData);
            }
        }
    } while (respData.next !== undefined);
    if (updatedRecordIds.length === 1) {
        flogger.info(`Update received for meter ${updatedRecordIds[0]}`);
    } else if (updatedRecordIds.length > 1) {
        flogger.info(`Updates received for meters ${updatedRecordIds.join(', ')}`);
    }
    // TODO Log the number of new records
    if (metersAdded) {
        appData.meters.sort(compareMeters);
        const routes = Object.keys(appData.metersByRoute);
        for (let i = 0; i < routes.length; i++) {
            appData.metersByRoute[routes[i]].sort(compareMeters);
        }
    }
    cleanModifiedMeters();
}

export async function createMeters(meterList) {
    await fetch(`${API_BASE}/meters`, {
        method: 'POST',
        mode: 'cors',
        credentials: 'include',
        body: JSON.stringify(meterList),
    });
    // TODO Use response from POST, instead of doing another GET to update
    await updateMeterData();
}

function convertCrewWithHours(value) {
    if (value === undefined || value === '') {
        return 'None';
    }
    if (!Array.isArray(value)) {
        return JSON.stringify(value);
    }
    if (value.find((arrayVal) => arrayVal.Name === undefined) !== undefined) {
        // One of the array values doesn't have a Name property
        return value.map((arrayVal) => JSON.stringify(arrayVal));
    }
    // TODO Check that all of our projects are storing the crew with hours in
    // {name: '', hours: 0} format
    // Make a copy so we don't have infinite recursion in our watcher
    const valueCopy = JSON.parse(JSON.stringify(value));
    valueCopy.sort((a, b) => a.Name.localeCompare(b.Name));
    return valueCopy.reduce((returnStr, nameHours, i) => `${returnStr}${i > 0 ? ', ' : ''}${nameHours.Name}${nameHours.Hours ? ` (${nameHours.Hours} hrs)` : ''}`, '');
}

export function meterChanges(meterId) {
    const flogger = logger.getLogger('meterChanges');
    // Returns a list of changes by comparing the data in appData.metersById vs
    // appData.modifiedMeters.
    const orig = appData.metersById[meterId];
    const mod = appData.modifiedMeters[meterId];
    if (!mod) {
        return [];
    }
    const changes = [];
    for (let i = 0; i < METER_EDIT_PROPS.length; i++) {
        const prop = METER_EDIT_PROPS[i];
        if (prop === undefined) {
            flogger.warning(`METER_EDIT_PROPS item ${i} is undefined. Skipping.`);
            continue;
        }
        if (prop.type === PROP_TYPES.SELECT_MULTI) {
            let origVal = JSON.stringify(orig[prop.key] || []);
            let modVal = JSON.stringify(mod[prop.key] || []);
            if (origVal !== modVal) {
                if (!orig[prop.key] || orig[prop.key].length === 0) {
                    origVal = 'None';
                } else {
                    origVal = orig[prop.key].map((propVal) => JSON.stringify(propVal)).join(', ');
                }
                if (!mod[prop.key] || mod[prop.key].length === 0) {
                    modVal = 'None';
                } else {
                    modVal = mod[prop.key].map((propVal) => JSON.stringify(propVal)).join(', ');
                }
                changes.push([prop.name, origVal, modVal]);
            }
        } else if (prop.type === PROP_TYPES.CREW_WITH_HOURS) {
            // Convert to a readable string and then compare the two
            const origVal = convertCrewWithHours(orig[prop.key]);
            const modVal = convertCrewWithHours(mod[prop.key]);
            if (origVal !== modVal) {
                changes.push([prop.name, origVal, modVal]);
            }
        } else if ((orig[prop.key] || '') !== (mod[prop.key] || '')) {
            changes.push([prop.name, orig[prop.key] || '', mod[prop.key] || '']);
        }
    }
    return changes;
}

export function createExportData(meters, options) {
    const flogger = logger.getLogger('createExportData');
    const { routes } = options;
    const startDate = options.startDate || new Date(0);
    const endDate = options.endDate || new Date();
    const installType = options.installType || 'Replace Meter';
    const meterTypes = options.meterTypes || ['Uninstallable', 'Installable', 'Completed', 'Incomplete'];
    flogger.info(`Exporting data for routes ${JSON.stringify(routes)} from ${startDate} to ${endDate}`);
    const csvData = [];
    for (let i = 0; i < meters.length; i++) {
        const meter = meters[i];
        if (meter['Install Type'] !== installType) {
            continue;
        }
        if (routes && !routes.includes(meter[ROUTE_KEY])) {
            continue;
        }
        const lastUpdate = new Date(meter.Version);
        if (this.dataExportLimitDates && (lastUpdate < startDate || lastUpdate > endDate)) {
            continue;
        }
        if (meter.Uninstallable && !meterTypes.includes('Uninstallable')) {
            continue;
        }
        if (!meter.Uninstallable && !meterTypes.includes('Installable')) {
            continue;
        }
        if (meter.Completed && !meterTypes.includes('Completed')) {
            continue;
        }
        if (!meter.Completed && !meterTypes.includes('Incomplete')) {
            continue;
        }
        const METER_SIZE_DIALS_MAP = {
            '5/8" x 3/4"': 5,
            '1"': 5,
            '1.5"': 5,
            '2"': 5,
            '3"': 5,
            '4"': 4,
            '6"': 4,
        };
        // const METER_SIZE_MULTIPLIER_MAP = {
        //     '5/8" x 3/4"': 100,
        //     '1"': 100,
        //     '1.5"': 1000,
        //     '2"': 1000,
        //     '3"': 1000,
        //     '4"': 1000,
        //     '6"': 1000,
        // };
        if (this.dataExportInstallType === 'Replace Meter') {
            csvData.push({
                'Meter ID': meter['Southern Software Meter ID'],
                'Meter Mfg': 'Sensus',
                'Meter Size': meter['Meter Size'],
                'Meter Units': 'Gallons',
                'Number Of Dials': METER_SIZE_DIALS_MAP[meter['Meter Size']],
                // 'Meter Multiplier': METER_SIZE_MULTIPLIER_MAP[meter['Meter Size']],
                'Last Read': meter['Outgoing Meter Reading'],
                'Current Reading': meter['New Meter Reading'],
                Longitude: meter.Longitude,
                Latitude: meter.Latitude,
                'Install Date': meter['Install Date'],
                'Serial No:': meter['New Meter ID'],
                'Meter ID No': meter['New Meter ID'],
                'TransMitter ID': meter['New Meter Transmitter ID'],
            });
        } else { // this.dataExportInstallType === 'Transmitter Only'
            csvData.push({
                'Meter ID': meter['Southern Software Meter ID'],
                'Meter Size': meter['Meter Size'],
                Longitude: meter.Longitude,
                Latitude: meter.Latitude,
                'Install Date': meter['Install Date'],
                'TransMitter ID': meter['New Meter Transmitter ID'],
            });
        }
    }
    if (csvData.length === 0) {
        this.dataExportError = 'No Results Found';
        return '';
    }
    // Specify the fields so that we have a header row, even if there is no data
    let fields = [
        'Meter ID', 'Meter Size', 'Longitude', 'Latitude', 'Install Date', 'TransMitter ID',
    ];
    if (this.dataExportInstallType === 'Replace Meter') {
        fields = fields.concat([
            'Meter Mfg', 'Meter Units', 'Number Of Dials', 'Last Read',
            'Current Reading', 'Serial No:', 'Meter ID No',
        ]);
    }
    return Papa.unparse(csvData, {
        quotes: true,
        columns: fields,
        header: true,
    });
}
