<!-- eslint-disable -->
<template>
  <div>
    <v-select
      label="Install Type" v-model="defaultedValue"
      :items="['Standard Meter Replacement', 'Hourly Rate']"
      :disabled="appData.access === 'RO'"></v-select>
    <v-text-field v-if="meter['Install Type'] === 'Hourly Rate'"
      v-model="meter['Install Hours']" label="Hours" type="number"></v-text-field>
  </div>
</template>

<script>
/* eslint-disable */
// TODO Fix this component to be more generic
import { appData } from '../meter-data';
// key: 'Install Type',
//         modifiable: true,
//         type: PROP_TYPES.SELECT,
//         choices: [
//             { text: 'Standard Meter Replacement', value: 'Standard Meter Replacement' },
//             { text: 'Hourly Rate', value: 'Hourly Rate' },
//         ],
export default {
    name: 'install-type-field',
    props: {
        meter: Object,
    },
    data: function data() {
        return {
            appData: appData,
        };
    },
    computed: {
        defaultedValue: {
            get: function get() {
                // If our v-model value is undefined, return null to use today's date
                if (!this.meter['Install Type']) {
                    return 'Standard Meter Replacement';
                }
                return this.meter['Install Type'];
            },
            set: function set(value) {
                this.meter['Install Type'] = value;
            },
        },
    },
};
</script>
